@import "src/assets/index.scss";

.tuttiINumeri {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.emptyData,
.ideeInnovativeProposte {
  align-self: stretch;
  position: relative;
  line-height: 70px;
}
.ideeInnovativeProposte {
  margin: 0;
  font-size: var(--h2-size);
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.numeroseOpportunitImprendit {
  align-self: stretch;
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 20px;
}
.statistiche,
.statisticsData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.statisticsData {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.statistiche {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 264px;
  max-width: 100%;
}
.b,
.ideeInnovativeProposte1 {
  align-self: stretch;
  position: relative;
  line-height: 70px;
}
.ideeInnovativeProposte1 {
  margin: 0;
  font-size: var(--h2-size);
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.numeroseOpportunitImprendit1 {
  align-self: stretch;
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 20px;
}
.parent,
.statistiche1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.parent {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.statistiche1 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 264px;
  max-width: 100%;
}
.b1,
.ideeInnovativeProposte2 {
  align-self: stretch;
  position: relative;
  line-height: 70px;
}
.ideeInnovativeProposte2 {
  margin: 0;
  font-size: var(--h2-size);
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.numeroseOpportunitImprendit2 {
  align-self: stretch;
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 20px;
}
.group,
.statistiche2 {
  display: flex;
  flex-direction: column;
}
.group {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.statistiche2 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 264px;
}
.contentHeader,
.statistiche2,
.statistics {
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.statistics {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-base);
  font-size: var(--font-size-39xl);
}
.contentHeader {
  width: 1088px;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--h2-size);
  color: var(--blue-dark);
  font-family: var(--cta-button);
}
.contentHeader,
.mainContent,
.numeri {
  display: flex;
  flex-direction: column;
}
.mainContent {
  [class*="ImageCardSection_proposedIdeasContentWrapper"] {
    padding-top: 0;
    padding-bottom: 0;
  }
  align-self: stretch;
  background-color: var(--blue-background);
  align-items: center;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  max-width: 100%;
  display: flex;
  // height: calc(100vh - 64px);
  // overflow-y: auto;
  justify-content: flex-start;
}
.numeri {
  width: 100%;
  position: relative;
  background-color: var(--blue-dark);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 832px;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 825px) {
  .b,
  .b1,
  .emptyData {
    font-size: var(--font-size-27xl);
    line-height: 56px;
  }
  .mainContent {
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  h3 {
    padding: 40px 12px 0;
    color: $blue-dark;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .statistics {
    padding: 0 12px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    .statistiche,
    .statistiche1,
    .statistiche2 {
      > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        > * {
          text-align: center;
        }
        b {
          color: $blue-dark;
          text-align: center;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 70px;
        }
        h3 {
          color: $blue-dark;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        div {
          @include BodyBigRegular;
        }
      }
    }
  }
  .emptyData {
    font-size: var(--font-size-16xl);
    line-height: 42px;
  }
  .ideeInnovativeProposte {
    padding-top: 0;
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .b {
    font-size: var(--font-size-16xl);
    line-height: 42px;
  }
  .ideeInnovativeProposte1 {
    padding-top: 0;
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .b1 {
    font-size: var(--font-size-16xl);
    line-height: 42px;
  }
  .ideeInnovativeProposte2 {
    padding-top: 0;
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .mainContent {
    padding: 0;
    box-sizing: border-box;
  }
}
