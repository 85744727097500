@import "src/assets/index.scss";

.button {
  @include CtaButton;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 8px;
  box-shadow: none;
  height: 48px;

  &.contrast {
    &.primary {
      background-color: $neutral-white !important;
      color: $blue-medium !important;
      &:hover {
        background: $blue-extralight !important;
        color: $blue-medium !important;
      }
      &:active {
        background: $blue-dark !important;
        color: $neutral-white !important;
      }
    }

    &.secondary {
      border-color: $neutral-white !important;
      background-color: transparent !important;
      color: $neutral-white !important;
      &:hover {
        border-color: $blue-extralight !important;
        background-color: transparent !important;
        color: $blue-extralight !important;
      }
      &:active {
        border-color: $neutral-white !important;
        background-color: transparent !important;
        color: $neutral-white !important;
      }
    }
  }
  &.primary {
    border: 0;
    background-color: $blue-regular;
    color: $neutral-white;
    &:disabled {
      background-color: $neutral-light;
      color: $neutral-dark;
    }
    svg {
      fill: $neutral-white;
      & * {
        fill: $neutral-white;
      }
    }
  }
  &.secondary {
    border: 1px solid $blue-regular;
    background-color: transparent;
    color: $blue-regular;
    &:hover {
      border: 1px solid $blue-medium !important;
      background-color: transparent !important;
      color: $blue-medium !important;
    }
    &:active {
      border: 1px solid $blue-dark !important;
      background-color: transparent !important;
      color: $blue-dark !important;
    }
    &:disabled {
      border-color: $neutral-regular !important;
      color: $neutral-regular !important;
    }
    svg {
      fill: $blue-regular;
      & * {
        fill: $blue-regular;
      }
    }
  }
  &.text {
    border: 0;
    background-color: transparent;
    color: $blue-regular;
    text-decoration: underline;
    &:hover {
      background-color: transparent !important;
      color: $blue-medium !important;
    }
    &:active {
      background-color: transparent !important;
      color: $blue-dark !important;
    }
    &:disabled {
      color: $neutral-regular !important;
    }
    svg {
      fill: $blue-regular;
      & * {
        fill: $blue-regular;
      }
    }
  }
  .icon {
    width: 24px;
    height: 24px;
  }
}
