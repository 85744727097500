@import "src/assets/index.scss";

.surveyIntro {
  padding: 48px 141px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #dfe5eb;
  min-height: calc(100vh - 65px);
  height: fit-content;
  .title {
    text-align: start;
  }
  .content {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    .subtitle {
      @include BodySmallRegular;
    }
    .ideaCriteria {
      display: flex;
      padding: 27px 24px;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      border-radius: 16px;
      background: #fff;
      gap: 16px;
      align-self: stretch;
      .paragraph {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .paragraphTitle {
          @include BodyBigSemibold;
        }
        .paragraphContent {
          @include BodyBigRegular;
          text-align: start;
        }
      }
    }
    .surveySteps {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;
      flex: 1 0 0;
      border-radius: 16px;
      background: #052147;
      color: #fff;
      .subtitle {
        margin-bottom: 8px;
      }
      .stepCard {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 16px;
        background: #05416d;
        .cardTitle {
          @include BodyBigSemibold;
          display: flex;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
        }
        .cardDescription {
          @include BodySmallRegular;
          display: flex;
          padding-left: 32px;
          justify-content: start;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          text-align: start;
        }
      }
    }
  }
  .button {
    align-self: end;
  }
}
