@import "src/assets/index.scss";

.stepPlaceholder {
  position: relative;
  line-height: 44px;
  display: inline-block;
  min-width: 16px;
}
.stepPlaceholderWrapper {
  width: 48px;
  border-radius: var(--br-5xs);
  border: 2px solid $blue-regular;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-sm);
}
.laTuaIdea {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.step {
  flex: 1;
  border-radius: var(--br-base);
  background-color: $blue-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xl) var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  min-width: 260px;
  max-width: 100%;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--neutral-white);
  font-family: var(--body-big-regular);
}
@media screen and (max-width: 1025px) {
  .stepPlaceholder {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 450px) {
  .stepPlaceholder {
    font-size: var(--font-size-lgi);
    line-height: 26px;
  }
}

.comeFunziona {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
}
.ilTuoPercorso {
  width: 446px;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
  color: var(--neutral-ultralight);
  display: inline-block;
  max-width: 117%;
  flex-shrink: 0;
}
.comeFunzionaParent,
.steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.comeFunzionaParent {
  width: 100%;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  max-width: 384px;
}
.steps {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.iconkeyboardArrowLeft {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  display: inline-block;
  font-family: var(--body-big-regular);
  color: var(--blue-medium);
  text-align: left;
  min-width: 127px;
}
.iconkeyboardArrowRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button,
.innovationProcess,
.processSteps {
  display: flex;
  align-items: center;
  justify-content: center;
}

.howWorkBtn {
  min-width: 200px;
}
.innovationProcess,
.processSteps {
  flex-direction: column;
  gap: var(--gap-29xl);
  max-width: 100%;
}
.processSteps {
  width: 1088px;
}
.innovationProcess {
  background-color: $blue-dark;
  align-self: stretch;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--neutral-white);
  font-family: var(--body-big-regular);
}
@media screen and (max-width: 1025px) {
  .comeFunziona {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 750px) {
  .processSteps {
    gap: var(--gap-5xl);
  }
  .innovationProcess {
    gap: var(--gap-5xl);
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .innovationProcess {
    padding: 40px 12px;
    .comeFunzionaParent {
      h1 {
        color: $neutral-white;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
      .ilTuoPercorso {
        @include BodyBigRegular;
        color: $neutral-white;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
      }
    }
    .processSteps {
      padding: 0 12px;
      .steps {
        gap: 16px;
        .step {
          display: flex;
          height: 192px;
          padding: 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          .stepPlaceholderWrapper {
            display: flex;
            width: 48px;
            height: 48px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
          }
        }
      }
      button {
        width: 100%;
      }
    }
  }
}
