@import "src/assets/index.scss";

.errorPage {
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  display: inline-flex;
  padding-bottom: 148px;
  flex-direction: column;
  align-items: center;
  background-color: #05416d;
  .content {
    display: flex;
    width: 588px;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    border-radius: 16px;
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      color: $neutral-white;
      .icon {
        width: 60px;
        height: 60px;
        padding: 5px;
        fill: $status-negative-regular;
      }
      .title {
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 8px;
      }
      .retry {
        @include BodyBigRegular;
      }
    }
    .ctaWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
    }
  }
}
