@import "src/assets/index.scss";

.logoTinextaIcon {
  align-self: stretch;
  height: 30px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.footerInfo {
  width: 133px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.piva10654631000 {
  flex: 1;
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 519px;
  max-width: 100%;
}
.image1Icon {
  height: 34px;
  width: 60px;
  position: relative;
  object-fit: cover;
}
.footerContent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 48px;
  max-width: 100%;
}
.footer,
.footerWrapper,
.home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer {
  align-self: stretch;
  justify-content: center;
  max-width: 100%;
}
.footerWrapper,
.home {
  overflow: hidden;
  justify-content: flex-start;
}
.footerWrapper {
  align-self: stretch;
  background-color: #050a29;
  padding: 64px 96px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 12px;
  color: $neutral-extralight;
  @include BodyBigRegular;
}
.home {
  overflow: hidden;
  width: 100%;
  // height: 100vh;
  position: relative;
  line-height: normal;
  letter-spacing: normal;
  .content {
    // overflow-y: auto;
    width: 100%;
    // height: calc(100% - 64px);
  }
}
@media screen and (max-width: 1025px) {
  .piva10654631000 {
    min-width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .footerContent {
    gap: var(--gap-5xl);
  }
  .footerWrapper {
    gap: var(--gap-base);
    padding-left: var(--padding-29xl);
    padding-right: var(--padding-29xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .footerWrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
