@mixin BodyBigRegular {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin BodyBigSemibold {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@mixin BodySmallRegular {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin BodySmallSemibold {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@mixin CtaButton {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

@mixin CtaTextlink {
  font-family: Inter;
  font-size: 16px;
  font-weight: Medium;
  line-height: 24px;
}
