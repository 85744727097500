@import "src/assets/index.scss";

.languageSelect {
  .select {
    [class="ant-select-selector"] {
      background-color: transparent !important;
      box-shadow: none !important;
      padding: 0 !important;
      border: 0 !important;
      [class="ant-select-selection-item"] {
        .languageText {
          display: none;
        }
      }
    }
    [class="ant-select-arrow"] {
      right: -8px;
    }
  }
}
.languageLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
