@import "src/assets/index.scss";

.radio {
  @include BodyBigSemibold;
  [class="ant-radio-inner"] {
    background-color: transparent;
    border: 2px solid $blue-regular;
    border-radius: 50px;
  }
  [class*="ant-radio-checked"] {
    [class="ant-radio-inner"] {
      background-color: transparent;
      border-color: $blue-regular;
      border-radius: 50px;
      &::after {
        border-radius: 50px;
        top: 85%;
        left: 85%;
        width: 8px;
        height: 8px;
        background-color: #1677ff;
        border: 0;
        transform: scale(1);
        opacity: 1;
        content: "";
      }
    }
  }
  &:hover {
    [class="ant-radio-inner"] {
      background-color: transparent !important;
      border: 2px solid $blue-regular !important;
    }
  }
}
