@import "src/assets/index.scss";

.fileUpload {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  > p:first-child {
    @include BodySmallSemibold;
    text-align: start;
  }
  .uploadWrapper {
    .draggerContainer {
      width: 100%;
      [class*="ant-upload-drag"] {
        background-color: transparent;
        border: 0;
      }
      [class*="ant-upload-btn"] {
        padding: 0;
      }
      [class="ant-upload-drag-container"] {
        display: flex;
        // TODO: cambiare in display block per renderlo width: 100%
        width: 100%;
        padding: 14px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        border: 1px dashed $neutral-extralight;
        background: $neutral-ultralight;
        .uploadIcon {
          width: 60px;
          height: 60px;
          fill: $blue-medium;
          & * {
            fill: $blue-medium;
          }
        }
        p {
          @include BodySmallRegular;
          color: $neutral-regular;
        }
      }
      [class="ant-upload-list ant-upload-list-text"] {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
      }
      .uploadContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        .uploadItem {
          display: flex;
          width: 100%;
          padding: 14px 12px;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid $blue-regular;
          background: $neutral-ultralight;
          .fileName {
            white-space: nowrap;
            text-align: start;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .actionWrapper {
            display: flex;
            gap: 8px;
            color: $neutral-regular;
          }
          &.error {
            color: $status-negative-dark;
            border: 1px solid $status-negative-dark;
          }
        }
        .subtext {
          @include BodySmallRegular;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          color: $status-negative-dark;
          svg {
            fill: $status-negative-dark;
            & * {
              fill: $status-negative-dark;
            }
          }
        }
      }
      .removeIcon {
        cursor: pointer;
        fill: $status-negative-dark;
        & * {
          fill: $status-negative-dark;
        }
      }
      .loadedFileIconWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .loadedFileIcon {
          cursor: pointer;
          fill: $blue-regular;
          & * {
            fill: $blue-regular;
          }
        }
      }
    }
  }
}
