@import "src/assets/index.scss";

.cardIdea {
  box-sizing: border-box;
  display: flex;
  max-width: 302px;
  height: 400px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  flex-shrink: 0;
  border-radius: 16px;
  background: $neutral-white;
  text-align: start;
  .ideaContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    overflow: hidden;
    color: $neutral-black;
    .idea {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .ideaDescription {
        @include BodyBigRegular;
        overflow-y: auto;
      }
    }
  }
  .ideaOwner {
    @include BodySmallRegular;
    color: $neutral-black;
  }
}
