@import "src/assets/index.scss";

.inCuiProporre,
.innovative,
.unoSpazioTinexta {
  margin: 0;
}
// .unoSpazioTinextaContainer {
//   margin: 0;
//   width: 472px;
//   position: relative;
//   font-size: inherit;
//   line-height: 44px;
//   font-weight: 700;
//   font-family: inherit;
//   display: inline-block;
//   max-width: 100%;
// }
.dallaConcezioneAllimplement,
.trasformaIlTuo {
  margin: 0;
}
.dallaConcezioneAllimplementContainer {
  @include BodyBigRegular;
  width: 412px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}
.heroBannerButtons,
.unoSpazioTinextaInCuiPropParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
}
.unoSpazioTinextaInCuiPropParent {
  flex: 0.8506;
  flex-direction: column;
  padding: 72px;
  box-sizing: border-box;
  gap: 32px;
  min-width: 400px;
}
.heroBannerChild {
  height: 100%;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 400px;
}
.mobileHeroBannerChild {
  display: none;
}
.content,
.heroBanner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.heroBanner {
  flex: 1;
  border-radius: 16px;
  background-color: $blue-medium;
  overflow: hidden;
  align-items: flex-start;
  row-gap: 20px;
}
.content {
  @include BodyBigRegular;
  align-self: stretch;
  background-color: $blue-background;
  align-items: center;
  padding: 24px 24px 0px 24px;
  box-sizing: border-box;
  text-align: left;
  color: $neutral-white;
}
@media screen and (max-width: 1025px) {
  // .unoSpazioTinextaContainer {
  //   font-size: var(--font-size-7xl);
  //   line-height: 35px;
  // }
  .heroBannerChild,
  .unoSpazioTinextaInCuiPropParent {
    flex: 1;
  }
  .heroBanner {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .heroBannerButtons {
    flex-wrap: wrap;
  }
  .unoSpazioTinextaInCuiPropParent {
    gap: var(--gap-base);
    padding-left: var(--padding-17xl);
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .heroBannerChild {
    min-width: 100%;
  }
  .content {
    gap: var(--gap-29xl);
  }
}
@media screen and (max-width: 450px) {
  .content {
    padding: 40px 12px 0;
    .heroBanner {
      padding: 0;
      flex-direction: column-reverse;
      .heroBannerChild {
        display: none;
      }
      .mobileHeroBannerChild {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 257px;
        flex: 1;
        position: relative;
        overflow: hidden;
        object-fit: cover;
        border-radius: 12px 12px 0px 0px;
      }
      .unoSpazioTinextaInCuiPropParent {
        padding: 0 24px 24px;
        gap: 8px;
        h1 {
          p {
            color: $neutral-white;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }
        .dallaConcezioneAllimplementContainer {
          p {
            @include BodyBigRegular;
          }
        }
      }
      .heroBannerButtons {
        padding: 16px 23px 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 16px;
        align-self: stretch;
      }
    }
  }
}
