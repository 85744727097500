@import "src/assets/index.scss";

.selection {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  text-align: start;
  .radioContainer {
    border-radius: 16px;
    background: $neutral-white;
    padding: 24px;
    [class*="ant-radio ant-wave-target"] {
      align-self: flex-start;
      padding-top: 3px;
    }
    .checkboxContainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      p {
        @include BodySmallRegular;
      }
    }
    &.active {
      outline: 2px solid $blue-regular;
    }
  }
  .textInputContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
  }
}

@media (max-width: 450px) {
  .selection {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    text-align: start;
    .radioContainer {
      border-radius: 16px;
      background: $neutral-white;
      padding: 24px;
      [class*="ant-radio ant-wave-target"] {
        align-self: flex-start;
        padding-top: 3px;
      }
      .checkboxContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        p {
          @include BodySmallRegular;
        }
      }
      &.active {
        outline: 2px solid $blue-regular;
      }
    }
    .textInputContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      gap: 16px;
    }
  }
}
