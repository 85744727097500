@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "./colors.scss";
@import "./tipography.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

:root {
  /* fonts */
  --cta-button: Inter;

  /* font sizes */
  --font-size-xs: 12px;
  --cta-button-size: 14px;
  --body-big-regular-size: 16px;
  --h2-size: 20px;
  --h1-size: 32px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;

  /* Colors */
  --blue-dark: #052147;
  --color-gray-100: #050a29;
  --blue-background: #dfe5eb;
  --neutral-extralight: #e1e1e5;
  --blue-regular: #005f89;
  --blue-medium: #05416d;
  --neutral-white: #fff;
  --neutral-black: #000;
  --blue-extralight: #b8d8e5;
  --neutral-regular: #5f5f5f;

  /* Gaps */
  --gap-base: 16px;
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-13xl: 32px;
  --gap-xs: 12px;

  /* Paddings */
  --padding-45xl: 64px;
  --padding-77xl: 96px;
  --padding-29xl: 48px;
  --padding-xl: 20px;
  --padding-base: 16px;
  --padding-23xl: 42px;
  --padding-8xl: 27px;
  --padding-3xs: 10px;
  --padding-13xl: 32px;
  --padding-9xs: 4px;
  --padding-85xl: 104px;
  --padding-53xl: 72px;
  --padding-17xl: 36px;
  --padding-28xl: 47px;
  --padding-xs: 12px;
  --padding-12xl: 31px;
  --padding-5xs: 8px;
  --padding-21xl: 40px;
  --padding-smi: 13px;
  --padding-lg: 18px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-base: 16px;
  --br-5xl: 24px;

  /* fonts */
  --body-big-regular: Inter;

  /* font sizes */
  --body-big-regular-size: 16px;
  --h1-size: 32px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --cta-button-size: 14px;
  --h2-size: 20px;

  /* Colors */
  --blue-dark: #052147;
  --blue-background: #dfe5eb;
  --neutral-extralight: #e1e1e5;
  --neutral-black: #000;
  --neutral-white: #fff;
  --blue-regular: #005f89;
  --blue-medium: #05416d;
  --blue-extralight: #b8d8e5;
  --neutral-ultralight: #f7f8fb;
  --neutral-regular: #5f5f5f;
  --neutral-dark: #333;

  /* Gaps */
  --gap-base: 16px;
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-77xl: 96px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-21xl: 40px;
  --gap-23xl: 42px;
  --gap-2xl: 21px;
  --gap-13xl: 32px;

  /* Paddings */
  --padding-45xl: 64px;
  --padding-77xl: 96px;
  --padding-xl: 20px;
  --padding-29xl: 48px;
  --padding-base: 16px;
  --padding-8xl: 27px;
  --padding-23xl: 42px;
  --padding-43xl: 62px;
  --padding-xs: 12px;
  --padding-13xl: 32px;
  --padding-5xs: 8px;
  --padding-2xl: 21px;
  --padding-9xs: 4px;
  --padding-75xl: 94px;
  --padding-42xl: 61px;
  --padding-12xl: 31px;
  --padding-17xl: 36px;
  --padding-9xl: 28px;
  --padding-5xl: 24px;
  --padding-2xs: 11px;
  --padding-sm: 14px;
  --padding-61xl: 80px;
  --padding-33xl: 52px;
  --padding-mid: 17px;
  --padding-lg: 18px;
  --padding-53xl: 72px;
  --padding-28xl: 47px;
  --padding-3xs: 10px;
  --padding-21xl: 40px;
  --padding-smi: 13px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-base: 16px;
  --br-5xs: 8px;
  --br-13xl: 32px;
  --br-5xl: 24px;
  --br-xs: 12px;

  /* fonts */
  --cta-button: Inter;

  /* font sizes */
  --font-size-xs: 12px;
  --cta-button-size: 14px;
  --h1-size: 32px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --h2-size: 20px;
  --font-size-base: 16px;
  --font-size-39xl: 58px;
  --font-size-16xl: 35px;
  --font-size-27xl: 46px;

  /* Colors */
  --blue-dark: #052147;
  --color-gray-100: #050a29;
  --blue-background: #dfe5eb;
  --neutral-extralight: #e1e1e5;
  --blue-regular: #005f89;
  --blue-medium: #05416d;
  --neutral-white: #fff;
  --neutral-regular: #5f5f5f;

  /* Gaps */
  --gap-base: 16px;
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-45xl: 64px;
  --padding-77xl: 96px;
  --padding-xl: 20px;
  --padding-29xl: 48px;
  --padding-base: 16px;
  --padding-8xl: 27px;
  --padding-23xl: 42px;
  --padding-87xl: 106px;
  --padding-50xl: 69px;
  --padding-xs: 12px;
  --padding-17xl: 36px;
  --padding-13xl: 32px;
  --padding-5xs: 8px;
  --padding-21xl: 40px;
  --padding-smi: 13px;
  --padding-lg: 18px;
  --padding-mini: 15px;

  /* Border radiuses */
  --br-base: 16px;
  --br-5xs: 8px;
}

body {
  box-sizing: border-box;
  font-family: Inter;
  overflow: hidden;
  .pageWithHeader {
    height: calc(100vh - 64px);
    overflow-y: auto;
  }
  & * {
    box-sizing: border-box;
  }
  b {
    font-weight: 600;
  }
  &,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    font-family: Inter;
    font-size: 32px;
    font-weight: Bold;
    line-height: 44px;
  }
  h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: Bold;
    line-height: 32px;
  }
  h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: Bold;
    line-height: 24px;
  }
}
.ant-wave {
  display: none;
}
