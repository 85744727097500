@import "src/assets/index.scss";

.startModal {
  display: flex;
  max-width: 394px;
  flex-direction: column;
  align-items: flex-start;
  .newIdea {
    display: flex;
    max-width: 394px;
    padding: 40px;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    border-radius: 16px 16px 0px 0px;
    border-bottom: 1px solid $neutral-extralight;
    background: $neutral-white;
  }
  .existingIdea {
    display: flex;
    width: 394px;
    padding: 40px;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    border-radius: 0px 0px 16px 16px;
    background: $neutral-white;
    box-shadow: 2px 6px 16px 0px rgba(0, 97, 140, 0.2);
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .existingIdeaText {
        @include BodySmallRegular;
      }
    }
    .btn {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    color: $neutral-black;
    text-align: start;
    .closeIcon {
      cursor: pointer;
    }
    h2 {
      align-self: flex-start;
    }
    p {
      @include BodySmallRegular;
    }
  }
}

@media (max-width: 390px) {
  .startModal {
    display: flex;
    max-width: calc(100% - 24px);
    flex-direction: column;
    align-items: flex-start;
    .newIdea {
      display: flex;
      max-width: 394px;
      padding: 40px;
      flex-direction: column;
      align-items: stretch;
      gap: 32px;
      border-radius: 16px 16px 0px 0px;
      border-bottom: 1px solid $neutral-extralight;
      background: $neutral-white;
    }
    .existingIdea {
      display: flex;
      width: 100%;
      padding: 40px;
      flex-direction: column;
      align-items: stretch;
      gap: 32px;
      border-radius: 0px 0px 16px 16px;
      background: $neutral-white;
      box-shadow: 2px 6px 16px 0px rgba(0, 97, 140, 0.2);
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: stretch;
      color: $neutral-black;
      text-align: start;
      .closeIcon {
        cursor: pointer;
      }
      h2 {
        align-self: flex-start;
      }
      p {
        @include BodySmallRegular;
      }
    }
  }
}
