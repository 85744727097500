@import "src/assets/index.scss";

.tab {
  cursor: pointer;
  display: inline-flex;
  height: 56px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: $neutral-regular;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  svg {
    fill: $neutral-regular;
    & * {
      fill: $neutral-regular;
    }
  }
  &.active {
    border-bottom: 2px solid $blue-regular;
    padding-bottom: 6px;
    color: $blue-dark;
    svg {
      fill: $blue-dark;
      & * {
        fill: $blue-dark;
      }
    }
  }
}

@media (max-width: 450px) {
  .tab {
    cursor: pointer;
    display: inline-flex;
    height: 56px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: $neutral-regular;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    svg {
      fill: $neutral-regular;
      & * {
        fill: $neutral-regular;
      }
    }
    &.active {
      border-bottom: 2px solid $neutral-ultralight;
      padding-bottom: 6px;
      color: $blue-dark;
      font-weight: 600;
      svg {
        fill: $blue-dark;
        & * {
          fill: $blue-dark;
        }
      }
    }
  }
}
