@import "src/assets/index.scss";

.dark {
  .box {
    .box1 {
      background-color: $blue-medium;
      color: $neutral-white;
      .button {
        background-color: $neutral-white;
        .cta {
          color: $blue-medium;
        }
      }
    }
  }
}

.proposedIdeasContentChild {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-base);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  min-width: 348px;
  min-height: 400px;
}
.ideeInnovativeProposte {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
}
.vuoiSaperneDi {
  align-self: stretch;
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 20px;
}
.ideeInnovativeProposteParent {
  width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.iconkeyboardArrowLeft {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  font-family: var(--body-big-regular);
  color: var(--neutral-white);
  text-align: left;
}
.iconkeyboardArrowRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-12xl);
  background-color: var(--blue-regular);
  box-shadow: 0 0 0#b8d8e5;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.box,
.box1 {
  display: flex;
  flex-direction: column;
  min-height: 400px;
}
.box1 {
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-75xl) var(--padding-xl);
  gap: var(--gap-21xl);
}
.box {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 348px;
  max-width: 100%;
}
.proposedIdeasContent,
.proposedIdeasContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.proposedIdeasContent {
  width: 1088px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-base);
}
.proposedIdeasContentWrapper {
  align-self: stretch;
  background-color: var(--blue-background);
  flex-direction: column;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--blue-medium);
  font-family: var(--body-big-regular);
}
@media screen and (max-width: 1025px) {
  .ideeInnovativeProposte {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 750px) {
  .proposedIdeasContentWrapper {
    gap: var(--gap-5xl);
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .proposedIdeasContentWrapper {
    padding: 40px 12px;
    .proposedIdeasContent {
      box-sizing: border-box;
      width: 100%;
      padding: 0;
      border-radius: 16px;
      background: $neutral-white;
      flex-direction: column;
      gap: 0;
    }
    .proposedIdeasContentChild {
      box-sizing: border-box;
      border-radius: 12px 12px 0 0;
      min-width: 0;
      width: 100%;
      min-height: 226px;
    }
    .box {
      box-sizing: border-box;
      min-width: 0;
      width: 100%;
      min-height: 0;
    }
    .box1 {
      box-sizing: border-box;
      min-width: 0;
      width: 100%;
      min-height: 0;
      height: fit-content;
      padding: 0;
      gap: 24px;
      padding: 24px;
      button {
        width: 100%;
      }
    }
  }

  .dark {
    .proposedIdeasContent {
      background-color: $blue-medium;
      padding: 0;
      gap: 0;
      .proposedIdeasContentChild {
        border-radius: 12px 12px 0 0;
      }
      .box1 {
        padding: 24px;
      }
    }
  }
}
