@import "src/assets/index.scss";

.iNostriObiettivi {
  margin: 0;
  width: 1088px;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.objectiveWrapper {
  width: 1088px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
  max-width: 100%;
  font-size: var(--h2-size);
  color: var(--neutral-dark);
  .objective {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    .iconContainer {
      display: flex;
      width: 72px;
      height: 72px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      border: 2px solid $blue-extralight;
      background: linear-gradient(0deg, #fff 0%, #f7f8fb 100%);
    }
    .objectiveText {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      text-align: center;
      p {
        @include BodyBigRegular;
        color: $neutral-regular;
      }
    }
  }
}
.iconkeyboardArrowLeft {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  display: inline-block;
  font-family: var(--body-big-regular);
  color: var(--neutral-white);
  text-align: left;
  min-width: 127px;
}
.iconkeyboardArrowRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button,
.iNostriObiettiviParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ourObjectivesBtn {
  min-width: 200px;
}
.iNostriObiettiviParent {
  align-self: stretch;
  background-color: var(--blue-background);
  flex-direction: column;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-23xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--h1-size);
  color: var(--neutral-black);
  font-family: var(--body-big-regular);
}
@media screen and (max-width: 1025px) {
  .iNostriObiettivi {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 750px) {
  .iNostriObiettiviParent {
    gap: var(--gap-2xl);
    padding-top: var(--padding-33xl);
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .iNostriObiettiviParent {
    padding: 32px 12px 40px;
    h1 {
      color: $neutral-black;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .objectiveWrapper {
      flex-direction: column;
      gap: 20px;
    }
    button {
      width: 100%;
    }
  }
}
