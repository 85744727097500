$blue-dark: #052147;
$blue-medium: #05416d;
$blue-regular: #005f89;
$blue-light: #71a8c0;
$blue-extralight: #b8d8e5;
$blue-ultralight: #f0f4f7;
$blue-background: #dfe5eb;

$neutral-black: #000000;
$neutral-dark: #333333;
$neutral-regular: #5f5f5f;
$neutral-light: #bbbaba;
$neutral-extralight: #e1e1e5;
$neutral-ultralight: #f7f8fb;
$neutral-white: #ffffff;

$status-positive-dark: #237804;
$status-positive-regular: #52c41a;
$status-positive-light: #eeffe6;

$status-warning-dark: #7f5511;
$status-warning-regular: #ffc53d;
$status-warning-light: #fffbe6;

$status-negative-dark: #ae0d06;
$status-negative-regular: #ff6264;
$status-negative-light: #f6e8ec;
