@import "src/assets/index.scss";

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  //   content: "";
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #0009;
  z-index: 9;
}

.modal.open {
  display: flex;
}
