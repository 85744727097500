@import "src/assets/index.scss";

.carouselContainer {
  width: 100%;
  [class*="slick-arrow"] {
    // border-radius: 32px;
    // background: $neutral-white;
    // width: 48px;
    // height: 48px;
    &[class*="slick-prev"] {
      left: -48px;
    }
    &::before {
      display: none;
    }
    svg {
      border-radius: 32px;
      background: $neutral-white;
      width: 48px;
      height: 48px;
    }
  }
  [class="slick-list"] {
    [class="slick-track"] {
      padding-top: 32px;
      [class*="slick-slide"] {
        > div {
          > div {
            margin: 0 auto;
            max-width: calc(100% - 48px);
          }
        }
      }
      [class*="slick-cloned"],
      [class*="slick-slide slick-active"],
      [class="slick-slide"],
      [class*="slick-slide slick-active slick-current"]
        + [class*="slick-slide slick-active"] {
        opacity: 0.3;
      }
      [class*="slick-slide slick-active slick-current"]
        + [class*="slick-slide slick-active"]
        + [class*="slick-slide slick-active"] {
        transform: translateY(-32px);
        opacity: 1;
      }
    }
  }
}
.mobileSlider {
  display: none;
}
.esploraTutteLe {
  margin: 0;
  width: 1088px;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.iconkeyboardArrowLeft {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.sliderItems {
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: var(--br-13xl);
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.insuretech {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.insuretechWrapper {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.ideaCardContentInner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.polizzeAssicurativeTemporane {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.ideaCardContent,
.polizzeAssicurativeTemporaneParent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.polizzeAssicurativeTemporaneParent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.ideaCardContent {
  gap: var(--gap-base);
}
.idea,
.ideaCard,
.ideaCardContent,
.sliderItems1 {
  display: flex;
  flex-direction: column;
}
.ideaCard {
  align-self: stretch;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
}
.idea,
.sliderItems1 {
  box-sizing: border-box;
}
.idea {
  align-self: stretch;
  height: 400px;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
}
.sliderItems1 {
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl) 0 var(--padding-13xl) 0;
  opacity: 0.5;
  min-width: 194px;
}
.insuretech1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.insuretechContainer {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.polizzeAssicurativeTemporane1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.polizzeAssicurativeTemporaneGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameParent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-base);
}
.frameParent,
.idea1,
.ideaInner,
.sliderItems2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ideaInner {
  align-self: stretch;
  flex: 1;
  align-items: flex-start;
}
.idea1,
.sliderItems2 {
  box-sizing: border-box;
}
.idea1 {
  align-self: stretch;
  height: 400px;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  align-items: flex-start;
  padding: var(--padding-13xl);
}
.sliderItems2 {
  flex: 1;
  align-items: center;
  padding: 0 0 var(--padding-45xl) 0;
  min-width: 194px;
  min-height: 464px;
}
.insuretech2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.frameDiv,
.insuretechFrame {
  display: flex;
  justify-content: center;
}
.insuretechFrame {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameDiv {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.polizzeAssicurativeTemporane2 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.frameContainer,
.polizzeAssicurativeTemporaneContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.polizzeAssicurativeTemporaneContainer {
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameContainer {
  gap: var(--gap-base);
}
.marioRossi {
  font-weight: 600;
}
.ideaDiMarioContainer {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.frameGroup,
.idea2,
.sliderItems3 {
  display: flex;
  flex-direction: column;
}
.frameGroup {
  align-self: stretch;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
}
.idea2,
.sliderItems3 {
  box-sizing: border-box;
}
.idea2 {
  align-self: stretch;
  height: 400px;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
}
.sliderItems3 {
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl) 0 var(--padding-13xl) 0;
  opacity: 0.5;
  min-width: 194px;
}
.iconkeyboardArrowRight {
  height: 32px;
  width: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.sliderItems4,
.sliderItemsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sliderItems4 {
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: var(--br-13xl);
  background-color: var(--neutral-white);
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.sliderItemsParent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.iconkeyboardArrowLeft1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  font-family: var(--body-big-regular);
  color: var(--blue-medium);
  text-align: left;
}
.iconkeyboardArrowRight1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button,
.exploreIdeas,
.ideasSlider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselBtn {
  min-width: 200px;
}
.exploreIdeas,
.ideasSlider {
  flex-direction: column;
  max-width: 100%;
}
.ideasSlider {
  width: 1088px;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--cta-button-size);
  color: var(--neutral-black);
}
.exploreIdeas {
  background-color: $blue-medium;
  align-self: stretch;
  padding: var(--padding-45xl) 96px;
  box-sizing: border-box;
  gap: var(--gap-29xl);
  text-align: center;
  font-size: var(--h1-size);
  color: var(--neutral-white);
  font-family: var(--body-big-regular);
}
@media screen and (max-width: 1025px) {
  .esploraTutteLe {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
  .sliderItemsParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .carouselContainer {
    padding: 0;
    .desktopSlider {
      display: none;
    }
    .mobileSlider {
      display: flex;
      gap: 18px;
      width: 100%;
      overflow-x: auto;
      [class*="CardIdea_cardIdea"] {
        width: 302px;
        height: 400px;
      }
    }
  }
  .exploreIdeas {
    gap: var(--gap-5xl);
    padding: 40px 24px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .exploreIdeas {
    padding: 40px 0;
    gap: 16px;
    h1 {
      color: $neutral-white;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .carouselContainer {
      padding: 0;
      .desktopSlider {
        display: none;
      }
      .mobileSlider {
        display: flex;
        padding: 0 12px;
        gap: 18px;
        width: 100%;
        overflow-x: auto;
        [class*="CardIdea_cardIdea"] {
          width: 302px;
          height: 400px;
        }
      }
    }
    button {
      display: none;
    }
  }
}

@media (max-width: 1600px) {
  [class="slick-list"] {
    [class="slick-track"] {
      padding-top: 32px;
      [class*="slick-slide"] {
        > div {
          > div {
            margin: 0 auto;
          }
        }
      }
      [class*="slick-cloned"],
      [class*="slick-slide slick-active"],
      [class="slick-slide"] {
        opacity: 0.3;
      }
      [class*="slick-slide slick-active slick-current"]
        + [class*="slick-slide slick-active"]
        + [class*="slick-slide slick-active"] {
        transform: translateY(0) !important;
        opacity: 0.3 !important;
      }
      [class*="slick-slide slick-active slick-current"]
        + [class*="slick-slide slick-active"] {
        transform: translateY(-32px) !important;
        opacity: 1 !important;
      }
    }
  }
}
