@import "src/assets/index.scss";
.loading {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  .loader {
    animation: endlessRotation 2.5s linear infinite;
  }
}

@keyframes endlessRotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
