@import "src/assets/index.scss";

.ideaForm {
  background: $blue-background;
  min-height: 100%;

  .stepWrapper {
    display: flex;
    width: 100%;
    height: 10px;
    align-items: flex-start;
    gap: 8px;
    padding: 40px 141px;
    margin-bottom: 48px;
    .step {
      height: 10px;
      flex: 1 0 0;
      border-radius: 8px;
      background-color: $neutral-white;
      &.current {
        background-color: $blue-regular;
      }
      &.done {
        background-color: $blue-light;
      }
    }
  }
  .form {
    display: inline-flex;
    padding-bottom: 74px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    width: 50%;
    .formContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      align-self: stretch;
      .titleContainer {
        display: flex;
        flex-direction: column;
        .backBtn {
          padding-left: 0;
          align-self: flex-start;
        }
      }
      .subtitle {
        @include BodySmallSemibold;
      }
      .answersWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        > * {
          width: 100%;
        }
      }
      .questionContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        > p {
          @include BodyBigRegular;
        }
        .answersWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          [class*="CardSelection_selection"] {
            > * {
              width: 100%;
              label {
                width: 100%;
              }
            }
          }
        }
      }
      .thankYou {
        @include BodyBigRegular;
        text-align: start;
      }
      .privacy {
        text-align: start;
        [class*="ant-checkbox"] {
          align-self: start;
          margin-top: 2px;
        }
      }
    }

    .ctaContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      .requiredField {
        @include BodySmallRegular;
      }
      .formBtn {
        width: 150px;
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 450px) {
  .ideaForm {
    background: $blue-background;
    min-height: 100%;

    .stepWrapper {
      display: flex;
      width: 100%;
      height: 10px;
      align-items: flex-start;
      gap: 8px;
      padding: 12px;
      margin-bottom: 48px;
      .step {
        height: 10px;
        flex: 1 0 0;
        border-radius: 8px;
        background-color: $neutral-white;
        &.current {
          background-color: $blue-regular;
        }
        &.done {
          background-color: $blue-light;
        }
      }
    }
    .form {
      display: inline-flex;
      padding-bottom: 74px;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      width: calc(100% - 24px);
      .formContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
        .titleContainer {
          display: flex;
          flex-direction: column;
          h1,
          h2 {
            text-align: start;
          }
          .backBtn {
            align-self: flex-start;
          }
        }
        .subtitle {
          @include BodySmallSemibold;
          text-align: start;
        }
        .answersWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          > * {
            width: 100%;
          }
        }
        .questionContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          h2 {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
          > p {
            @include BodyBigRegular;
          }
          .answersWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
          }
        }
        .thankYou {
          @include BodyBigRegular;
          text-align: start;
        }
      }
      .formBtn {
        width: 150px;
        align-self: flex-end;
      }
    }
  }
}
