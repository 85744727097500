@import "src/assets/index.scss";

.benefitsListFirst {
  height: 480px;
  flex: 1;
  position: relative;
  border-radius: var(--br-base);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 322px;
  min-height: 480px;
}
.crescitaProfessionaleE {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
}
.tinextaOffreUn {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.benefitsListFirstParent,
.benefitsListSecond {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.benefitsListSecond {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-77xl) 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  min-width: 322px;
}
.benefitsListFirstParent {
  width: 1088px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap-77xl);
}
.incrementoDellefficienzaE {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
}
.tinextaAgisceCome {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.incrementoDellefficienzaECParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-77xl) 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  min-width: 322px;
  max-width: 100%;
}
.frameChild {
  height: 480px;
  flex: 1;
  position: relative;
  border-radius: var(--br-base);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 322px;
}
.benefits,
.frameParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.frameParent {
  width: 1088px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-77xl);
}
.benefits {
  align-self: stretch;
  background-color: var(--blue-background);
  flex-direction: column;
  padding: var(--padding-45xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  text-align: left;
  font-size: var(--h1-size);
  color: var(--neutral-black);
  font-family: var(--body-big-regular);
}
@media screen and (max-width: 1125px) {
  .benefitsListFirstParent,
  .frameParent {
    gap: var(--gap-29xl);
  }
  .benefits {
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1025px) {
  .crescitaProfessionaleE,
  .incrementoDellefficienzaE {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 750px) {
  .benefitsListSecond {
    padding-top: var(--padding-43xl);
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
  .benefitsListFirstParent {
    gap: var(--gap-5xl);
  }
  .incrementoDellefficienzaECParent {
    padding-top: var(--padding-43xl);
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
  .benefits,
  .frameParent {
    gap: var(--gap-5xl);
  }
  .benefits {
    padding-top: var(--padding-8xl);
    padding-bottom: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .benefits {
    padding: 40px 12px;
    h1 {
      color: $neutral-black;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .benefitsListFirstParent {
      gap: 16px;
      .benefitsListSecond {
        padding: 0;
      }
    }
    .frameParent {
      flex-direction: column-reverse;
      padding-top: 16px;
      .incrementoDellefficienzaECParent {
        padding: 0;
      }
    }
    .benefitsListFirst,
    .frameChild {
      min-width: 351px;
      min-height: 340px;
      height: 340px;
    }
  }
}
