@import "src/assets/index.scss";

.inputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  .label {
    color: $neutral-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: start;
  }

  .inputWrapper {
    box-sizing: border-box;
    display: flex;
    height: 48px;
    padding: 14px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid $neutral-extralight;
    background: $neutral-ultralight;
    .input {
      width: 100%;
      border: 0;
      background-color: transparent;
      color: $neutral-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      &::placeholder {
        color: $neutral-regular;
      }
      &:focus-visible {
        outline: transparent;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
    .icon {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }

    &:has(.input:focus-visible) {
      border-radius: 8px;
      border: 1px solid $blue-regular;
      background: $neutral-ultralight;
    }
    &:has(.input:disabled) {
      cursor: not-allowed;
      border-radius: 8px;
      border: 1px solid $neutral-light;
      background: $neutral-extralight;
      .icon {
        svg {
          fill: $neutral-regular;
          & * {
            fill: $neutral-regular;
          }
        }
      }
    }
  }

  .subText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &.error {
    color: $status-negative-dark;
    .inputWrapper,
    :has(.input:focus-visible) {
      border: 1px solid $status-negative-dark;
      .input {
        color: $status-negative-dark;
      }
    }
    .subText {
      svg {
        fill: $status-negative-dark;
        & * {
          fill: $status-negative-dark;
        }
      }
    }
  }
}
