@import "src/assets/index.scss";

.chip {
  @include BodySmallSemibold;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: $blue-extralight;
}
