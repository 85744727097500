.logoTinextaIcon {
  align-self: stretch;
  height: 30px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.footerLogo {
  width: 133px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-base) 0;
  box-sizing: border-box;
}
.piva10654631000 {
  flex: 1;
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 519px;
  max-width: 100%;
}
.image1Icon {
  height: 34px;
  width: 60px;
  position: relative;
  object-fit: cover;
}
.footer,
.footerContent,
.footerWrapper {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.footerContent {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
}
.footer,
.footerWrapper {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.footerWrapper {
  background-color: var(--color-gray-100);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-45xl) var(--padding-77xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--neutral-extralight);
  font-family: var(--cta-button);
}
@media screen and (max-width: 825px) {
  .piva10654631000 {
    min-width: 100%;
  }
}
@media screen and (max-width: 675px) {
  .footerContent {
    gap: var(--gap-5xl);
  }
  .footerWrapper {
    gap: var(--gap-base);
    padding-left: var(--padding-29xl);
    padding-right: var(--padding-29xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .footerWrapper {
    padding: 40px 24px;
    .footerContent {
      justify-content: center;
    }
    .piva10654631000 {
      text-align: center;
    }
  }
}
