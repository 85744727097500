@import "src/assets/index.scss";

.navbar {
  display: flex;
  width: 100%;
  padding: 8px 40px 0px 40px;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;

  .tabWrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin: 0 auto;
  }

  .iconWrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }

  .cancelButton {
    color: $status-negative-dark !important;
  }
  .menuIcon {
    display: none;
  }
  .logo {
    cursor: pointer;
  }
  &.form {
    .logo {
      width: 100%;
      transform: translateX(24px);
    }
  }
}
.mobileMenu {
  display: none;
}
.mobileTabWrapper {
  display: none;
}

@media (max-width: 450px) {
  .navbar {
    position: relative;
    display: flex;
    width: 100%;
    padding: 13px 12px;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    .logo {
      height: 30px;
    }
    .tabWrapper {
      display: none;
      align-items: flex-start;
      gap: 8px;
      margin: 0 auto;
    }

    .iconWrapper {
      display: flex;
      align-items: flex-start;
      gap: 24px;
    }

    .cancelButton {
      color: $status-negative-dark !important;
    }
    .menuIcon {
      display: block;
    }
    &.form {
      .logo {
        width: fit-content;
      }
    }
  }
  .mobileMenu {
    position: fixed;
    top: 65px;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    z-index: 9;
    display: block;
    border-top: 1px solid $neutral-black;
    &.menuClose {
      display: none;
    }
  }
  .mobileTabWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 65px;
    left: 0;
    transform: translateX(0);
    transition: transform 1s ease;
    z-index: 99;
    > * {
      border-bottom: 1px solid $neutral-ultralight;
      background-color: $neutral-white;
    }
    &.tabClose {
      transform: translateX(-200%);
      transition: transform 1s ease;
    }
    .languageSelectMobile {
      [class*="LanguageSelect_languageLabel"] {
        justify-content: center;
      }
    }
  }
}
