@import "src/assets/index.scss";

.selectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .label {
    @include BodySmallSemibold;
    color: $neutral-black;
  }
  .select {
    width: 100%;
    height: 48px;
    &:hover {
      [class="ant-select-selector"] {
        border: 0;
      }
    }
    &[class*="ant-select-focused"] {
      [class="ant-select-selector"] {
        border-color: $neutral-black !important;
      }
    }
    [class="ant-select-selector"] {
      background-color: $neutral-ultralight;
      border-color: transparent;
      box-shadow: none;
      &::after {
        content: none;
      }
    }
    [class="ant-select-selection-placeholder"] {
      text-align: start;
      color: $neutral-regular;
    }
    [class="ant-select-selection-item"] {
      text-align: start;
      color: $neutral-black;
    }
  }
}
