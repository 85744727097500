@import "src/assets/index.scss";

.esploraEVota {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.insuretech {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.ideaCategoryNames {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.ideaCategoryRow {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.polizzeAssicurativeTemporane {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.ideaTitleRow {
  align-self: stretch;
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.idea,
.ideaContent,
.ideaDetails,
.ideaTitleRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ideaDetails {
  align-self: stretch;
  gap: var(--gap-base);
}
.idea,
.ideaContent {
  box-sizing: border-box;
}
.ideaContent {
  align-self: stretch;
  padding: 0 0 var(--padding-85xl);
  min-height: 236px;
}
.idea {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  padding: var(--padding-13xl);
  min-width: 402px;
  max-width: 100%;
}
.insuretech1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.frameWrapper,
.insuretechWrapper {
  display: flex;
  justify-content: center;
}
.insuretechWrapper {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameWrapper {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.polizzeAssicurativeTemporane1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.frameGroup,
.polizzeAssicurativeTemporaneParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.polizzeAssicurativeTemporaneParent {
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameGroup {
  gap: var(--gap-base);
}
.marioRossi {
  font-weight: 600;
}
.ideaDiMarioContainer {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 236px;
}
.idea1,
.ideaColumns {
  display: flex;
  max-width: 100%;
}
.idea1 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 402px;
}
.ideaColumns {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.insuretech2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.insuretechContainer {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameWrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.polizzeAssicurativeTemporane2 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.frameDiv,
.polizzeAssicurativeTemporaneGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.polizzeAssicurativeTemporaneGroup {
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameDiv {
  gap: var(--gap-base);
}
.marioRossi1 {
  font-weight: 600;
}
.ideaDiMarioContainer1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.frameContainer,
.idea2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameContainer {
  align-self: stretch;
  justify-content: space-between;
  min-height: 236px;
}
.idea2 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 402px;
  max-width: 100%;
}
.insuretech3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.frameWrapper2,
.insuretechFrame {
  display: flex;
  justify-content: center;
}
.insuretechFrame {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameWrapper2 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.polizzeAssicurativeTemporane3 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative3 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.polizzeAssicurativeTemporaneContainer {
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameParent1,
.ideaInner,
.polizzeAssicurativeTemporaneContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent1 {
  gap: var(--gap-base);
}
.ideaInner {
  padding: 0 0 var(--padding-85xl);
  box-sizing: border-box;
  min-height: 236px;
}
.idea3,
.ideaColumns1 {
  display: flex;
  max-width: 100%;
}
.idea3 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 402px;
}
.ideaColumns1 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.insuretech4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.insuretechWrapper1 {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameWrapper3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.polizzeAssicurativeTemporane4 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative4 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.polizzeAssicurativeTemporaneParent1 {
  align-self: stretch;
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameParent2,
.idea4,
.ideaChild,
.polizzeAssicurativeTemporaneParent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent2 {
  align-self: stretch;
  gap: var(--gap-base);
}
.idea4,
.ideaChild {
  box-sizing: border-box;
}
.ideaChild {
  align-self: stretch;
  padding: 0 0 var(--padding-85xl);
  min-height: 236px;
}
.idea4 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  padding: var(--padding-13xl);
  min-width: 402px;
  max-width: 100%;
}
.insuretech5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}
.insuretechWrapper2 {
  border-radius: var(--br-5xl);
  background-color: var(--blue-extralight);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-base);
}
.frameWrapper4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.polizzeAssicurativeTemporane5 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.forniscePolizzeAssicurative5 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.frameParent4,
.polizzeAssicurativeTemporaneParent2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.polizzeAssicurativeTemporaneParent2 {
  gap: var(--gap-5xs);
  font-size: var(--h2-size);
}
.frameParent4 {
  gap: var(--gap-base);
}
.marioRossi2 {
  font-weight: 600;
}
.ideaDiMarioContainer2 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.frameParent3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 236px;
}
.idea5,
.ideaColumns2 {
  display: flex;
  max-width: 100%;
}
.idea5 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--neutral-white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  box-sizing: border-box;
  min-width: 402px;
}
.ideaColumns2 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.ideaCards {
  align-self: stretch;
  gap: var(--gap-base);
  display: flex;
  flex-wrap: wrap;
  > * {
    max-width: 100%;
    flex: 1 1 calc(50% - 8px);
  }
}
.ideasGrid,
.ideasHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.ideasGrid {
  align-self: stretch;
  padding: 0 2px;
  box-sizing: border-box;
  font-size: var(--cta-button-size);
  color: var(--neutral-black);
}
.ideasHeader {
  width: 1088px;
  gap: var(--gap-13xl);
}
.iconkeyboardArrowLeft {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  display: inline-block;
  font-family: var(--cta-button);
  color: var(--blue-regular);
  text-align: left;
  min-width: 83px;
}
.iconkeyboardArrowRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button {
  cursor: pointer;
  border: 1px solid var(--blue-regular);
  padding: var(--padding-3xs) 57px;
  background-color: transparent;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.archivioIdeeDiBusiness,
.ideas {
  display: flex;
  flex-direction: column;
}
.ideas {
  align-self: stretch;
  background-color: var(--blue-background);
  align-items: center;
  justify-content: center;
  padding: var(--padding-23xl) var(--padding-xl) var(--padding-45xl);
  box-sizing: border-box;
  gap: 40px;
  max-width: 100%;
  text-align: left;
  font-size: var(--h2-size);
  color: var(--blue-dark);
  font-family: var(--cta-button);
}
.archivioIdeeDiBusiness {
  width: 100%;
  position: relative;
  background-color: var(--blue-dark);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 832px;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .ideas {
    padding-top: var(--padding-8xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .ideaContent {
    gap: var(--gap-base);
  }
  .idea,
  .idea1,
  .idea2 {
    gap: var(--gap-xl);
    min-width: 100%;
  }
  .ideaInner {
    gap: var(--gap-base);
  }
  .idea3 {
    gap: var(--gap-xl);
    min-width: 100%;
  }
  .ideaChild {
    gap: var(--gap-base);
  }
  .idea4,
  .idea5 {
    gap: var(--gap-xl);
    min-width: 100%;
  }
  .ideasHeader {
    gap: var(--gap-base);
  }
  .ideas {
    gap: var(--gap-xl);
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .esploraEVota,
  .polizzeAssicurativeTemporane,
  .polizzeAssicurativeTemporane1,
  .polizzeAssicurativeTemporane2,
  .polizzeAssicurativeTemporane3,
  .polizzeAssicurativeTemporane4,
  .polizzeAssicurativeTemporane5 {
    font-size: var(--body-big-regular-size);
    line-height: 26px;
  }
  .ideas {
    padding: 0 12px 40px;
    .ideasHeader {
      gap: 24px;
      h3 {
        color: $blue-dark;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
      .ideasGrid {
        .ideaCards {
          align-self: stretch;
          gap: var(--gap-base);
          display: flex;
          flex-wrap: wrap;
          > * {
            max-width: 100%;
            flex: 1 1 100%;
          }
        }
      }
    }
    .button {
      width: calc(100% - 116px);
    }
  }
}
