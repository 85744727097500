@import "src/assets/index.scss";

.showCode {
  opacity: 1;
}
.hideCode {
  opacity: 0;
  transition: opacity 0.8s ease;
}
.iconsuccess {
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  fill: $status-positive-regular;
  & * {
    fill: $status-positive-regular;
  }
}
.segnalazioneInviataCorrettam {
  color: $neutral-white;
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 32px;
  font-weight: 400;
  font-family: inherit;
}
.successIcon {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.copiaESalva {
  margin: 0;
  width: 618px;
  position: relative;
  font-size: var(--h1-size);
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 122%;
  flex-shrink: 0;
}
.inQuestoModo {
  align-self: stretch;
  line-height: 24px;
}
.b,
.iconcopy,
.inQuestoModo {
  position: relative;
}
.b {
  font-size: var(--h2-size);
  line-height: 32px;
  font-family: var(--cta-button);
  color: var(--neutral-white);
  text-align: center;
}
.iconcopy {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  fill: $neutral-white;
  & * {
    fill: $neutral-white;
  }
}
.copyIcon {
  cursor: pointer;
  border: 1px solid var(--blue-light);
  padding: 22px 31px;
  background-color: var(--blue-regular);
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  white-space: nowrap;
  max-width: 100%;
}
.copyIcon:hover {
  background-color: #2685b0;
  border: 1px solid #3d758c;
  box-sizing: border-box;
}
.importante {
  font-weight: 600;
}
.importante,
.span {
  font-family: var(--cta-button);
}
.conservaIlCodice {
  font-weight: 600;
}
.blankLine,
.ilCodice,
.importanteConservaIlCodice {
  margin: 0;
}
.importanteConservaIlContainer {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.successMessage,
.verificationInstructions {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.verificationInstructions {
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  font-size: var(--body-big-regular-size);
}
.successMessage {
  gap: 32px;
}
.iconkeyboardArrowLeft {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  font-family: var(--cta-button);
  color: var(--blue-medium);
  text-align: left;
}
.iconkeyboardArrowRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs);
  background-color: var(--neutral-white);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.iconkeyboardArrowLeft1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 102px;
}
.iconkeyboardArrowRight1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button1,
.buttonLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button1 {
  cursor: pointer;
  border-radius: var(--br-5xs);
  border: 1px solid var(--neutral-white);
  align-items: center;
  padding: 10px 11px;
  gap: var(--gap-5xs);
}
.buttonLabel {
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.cTAButton,
.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: var(--cta-button-size);
}
.successContainer {
  width: 588px;
  border-radius: 16px;
  padding: 40px;
  box-sizing: border-box;
  gap: 48px;
  text-align: center;
  font-size: var(--h2-size);
  color: var(--neutral-white);
  font-family: var(--cta-button);
}
.content,
.nuovaSegnalazione {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.content {
  overflow-y: auto;
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}
.nuovaSegnalazione {
  width: 100%;
  position: relative;
  background-color: var(--blue-medium);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  // padding: 0 0 46px;
  box-sizing: border-box;
  gap: 14px;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 750px) {
  .copiaESalva {
    font-size: 26px;
    line-height: 35px;
  }
  .successContainer {
    padding-top: var(--padding-7xl);
    padding-bottom: var(--padding-7xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 675px) {
  .successMessage {
    gap: 16px;
  }
  .successContainer {
    gap: var(--gap-5xl);
  }
}
@media screen and (max-width: 450px) {
  .segnalazioneInviataCorrettam {
    font-size: var(--body-big-regular-size);
    line-height: 26px;
  }
  .copiaESalva {
    font-size: 19px;
    line-height: 26px;
  }
  .buttonLabel {
    flex-wrap: wrap;
  }
}
