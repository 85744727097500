@import "src/assets/index.scss";

.sendCodeModal {
  display: flex;
  width: 394px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: $neutral-white;
  box-shadow: 2px 6px 16px 0px rgba(0, 97, 140, 0.2);
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .closeIcon {
      cursor: pointer;
      align-self: end;
    }
    h2 {
      text-align: start;
      margin-bottom: 8px;
    }
    p {
      @include BodySmallRegular;
      text-align: start;
      margin-bottom: 16px;
    }
  }
  .sendBtn {
    width: 100%;
  }
}
