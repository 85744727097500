@import "src/assets/index.scss";

.checkbox {
  @include BodyBigSemibold;
  &.circle {
    [class="ant-checkbox-inner"] {
      background-color: transparent;
      border: 2px solid $blue-regular;
      border-radius: 50px;
    }
    [class*="ant-checkbox-checked"] {
      [class="ant-checkbox-inner"] {
        background-color: transparent;
        border-color: $blue-regular;
        border-radius: 50px;
        &::after {
          border-radius: 50px;
          top: 50%;
          inset-inline-start: 50%;
          width: 8px;
          height: 8px;
          background-color: #1677ff;
          border: 0;
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
          content: "";
        }
      }
    }
    &:hover {
      [class="ant-checkbox-inner"] {
        background-color: transparent !important;
        border: 2px solid $blue-regular !important;
      }
    }
  }
  &.square {
    [class="ant-checkbox-inner"] {
      background-color: transparent;
      border: 2px solid $blue-regular;
    }
    [class*="ant-checkbox-checked"] {
      [class="ant-checkbox-inner"] {
        background-color: $blue-regular !important;
        border-color: $blue-regular;
        &::after {
        }
      }
    }
    &:hover {
      [class="ant-checkbox-inner"] {
        border: 2px solid $blue-regular !important;
      }
    }
  }
}
