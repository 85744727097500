@import "src/assets/index.scss";

.esploraIlNostro,
.unArchivioCon {
  align-self: stretch;
  position: relative;
}
.unArchivioCon {
  margin: 0;
  font-size: inherit;
  line-height: 44px;
  font-weight: 700;
  font-family: inherit;
}
.esploraIlNostro {
  font-size: var(--body-big-regular-size);
  line-height: 24px;
}
.unArchivioConOltre200IdeeParent {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}
.iconkeyboardArrowLeft {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  display: inline-block;
  font-family: var(--cta-button);
  color: var(--blue-medium);
  text-align: left;
  min-width: 127px;
}
.iconkeyboardArrowRight {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-12xl);
  background-color: var(--neutral-white);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.iconkeyboardArrowLeft1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.cta1 {
  position: relative;
  font-size: var(--cta-button-size);
  line-height: 24px;
  font-family: var(--cta-button);
  color: var(--neutral-white);
  text-align: left;
}
.iconkeyboardArrowRight1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.button1 {
  cursor: pointer;
  border: 1px solid var(--neutral-white);
  padding: var(--padding-3xs) var(--padding-13xl);
  background-color: transparent;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.heroCTA,
.heroContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.heroContent {
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-53xl);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  max-width: 100%;
  flex-shrink: 0;
}
.frame26086421 {
  height: 466px;
  width: 889px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 233px);
  left: 590px;
  object-fit: cover;
}
.content,
.hero {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.hero {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--blue-medium);
  overflow: hidden;
  align-items: flex-start;
  position: relative;
}
.content {
  align-self: stretch;
  background-color: var(--blue-background);
  align-items: center;
  padding: var(--padding-23xl) var(--padding-77xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--h1-size);
  color: var(--neutral-white);
  font-family: var(--cta-button);
}
@media screen and (max-width: 1050px) {
  .unArchivioCon {
    font-size: var(--font-size-7xl);
    line-height: 35px;
  }
}
@media screen and (max-width: 750px) {
  .heroCTA {
    flex-wrap: wrap;
  }
  .heroContent {
    gap: var(--gap-base);
    padding-left: var(--padding-17xl);
    padding-right: var(--padding-17xl);
    box-sizing: border-box;
  }
  .content {
    gap: var(--gap-29xl);
    padding: var(--padding-8xl) var(--padding-29xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .content {
    padding: 40px 12px;
    .hero {
      flex-direction: column-reverse;
      .heroContent {
        padding: 24px;
        .heroCTA {
          flex-direction: column;
          align-items: stretch;
          padding: 0 23px;
          width: 100%;
        }
      }
      .frame26086421 {
        box-sizing: border-box;
        position: static;
        width: 100%;
        height: 257px;
        flex-shrink: 0;
        border-radius: 16px 16px 0 0;
      }
    }
  }
}
